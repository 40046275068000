// * AG Grid styles
@use '~ag-grid-community/styles' as ag;
@include ag.grid-styles();

// REVIEW >> Temp fix... Need to sync DS colors to Figma
$header-font-color: #12161d; //var(--bw-grey-900, #12161D);
$header-bg-color: #f9fafc;
$font-color: orange;
$bg-color: lightblue;
$accent-color: blue;
$disabled-color: lightsalmon;
$border-color: rgba(172, 175, 181, 0.2); // var(--bw-border, rgba(172, 175, 181, 0.20))
$border-thickness: 1px;

// * Entire grid CSS from Figma:
// border-radius: var(--M, 16px);
// border: 1px solid var(--bw-grey-200, #EFF1F5); // rgb(239, 241, 245)
// background: var(--bw-white, #FFF);
// box-shadow: 0px 0px 6px -2px rgba(24, 50, 139, 0.06), 0px 4px 4px -3px rgba(24, 50, 139, 0.02), 0px 8px 6px -6px rgba(24, 50, 139, 0.01);

// ! Use the CSS variables whenever possible (as opposed to creating ag class specific styles
// ? https://ag-grid.com/react-data-grid/global-style-customisation-variables/
// ? More info: https://www.ag-grid.com/react-data-grid/global-style-customisation/
.ag-theme-fsg {
  --ag-font-family: var(--font-main); // * (`font-family: `'Gill Sans', sans-serif`) - Font family used for all text
  --ag-font-size: 14px; // * `0`, `4px` or `50%` - Default font size for text in the grid
  // --ag-icon-font-family: ; // * The icon font used by the grid.

  --ag-header-foreground-color: #{$header-font-color}; // * Colour of text and icons in the header - `Salmon` or `#fff`
  --ag-header-background-color: #{$header-bg-color}; // * Background colour for all headers, including the grid header, panels etc

  --ag-borders: solid #{$border-thickness}; // * `solid 1px` or `none` - Enable or disable borders around most UI elements in the grid. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders. Use the other --ag-borders-* variables for more fine grained control over which UI elements get borders.
  --ag-border-color: #{$border-color}; // * Colour for border around major UI components like the grid itself, headers; footers and tool panels.
  --ag-borders-critical: hotpink; // * `solid 1px` or `none` - Enable or disable borders that are critical to UX, e.g. those between headers and rows. Themes that disable borders generally may want to enable these borders. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
  --ag-borders-secondary: blue; // * `solid 1px` or `none` - Draw decorative borders separating UI elements within components. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
  --ag-secondary-border-color: cyan; // * Colour for borders used to separate elements within a major UI component
  --ag-row-border-style: solid; // * `solid` or `dotted` - Default border style for the grid rows. Set this to a border style, e.g. solid, dotted.
  --ag-row-border-width: #{$border-thickness}; // * The thickness of the border between grid rows. Set this to a border thickness, e.g. 1px.
  --ag-row-border-color: #{$border-color}; // * Colour of the border between grid rows, or transparent to display no border - `Salmon` or `#fff`
  --ag-cell-horizontal-border: solid #{$border-color}; // * `solid red` - Default border for cells. This can be used to specify the border-style and border-color properties e.g. dashed red but the border-width is fixed at 1px. Set to solid transparent to show no border.

  --ag-header-column-separator-display: block; // * `block` to show or `none` to hide - Whether to display the header column separator - a vertical line that displays between every header cell
  --ag-header-column-separator-height: 100%; // * `0`, `4px` or `50%` - Height of the header column separator. Percentage values are relative to the header height.
  --ag-header-column-separator-width: #{$border-thickness}; // * `0`, `4px` or `50%` - Width of the header column separator
  --ag-header-column-separator-color: #{$border-color}; // * Colour of the header column separator - `Salmon` or `#fff`
  --ag-header-column-resize-handle-display: none; // * `block` to show or `none` to hide - Whether to show the header column resize handle - a vertical line that displays only between resizeable header columns, indicating where to drag in order to resize the column.
  // --ag-header-column-resize-handle-height: 60%; // * `0`, `4px` or `50%` - Height of the header resize handle. Percentage values are relative to the header height.
  // --ag-header-column-resize-handle-width: #{$border-thickness}; // * `0`, `4px` or `50%` - Width of the header resize handle.
  // --ag-header-column-resize-handle-color: #{$border-color}; // * Colour of the header resize handle - `Salmon` or `#fff`

  // --ag-active-color: ; // * (Quartz theme only) accent colour used for checked checkboxes, range selections, and input focus outlines in the Quartz theme
  // --ag-alpine-active-color: #{$accent-color}; // * (Alpine theme only) accent colour used for checked checkboxes, range selections, row hover, row selections, selected tab underlines, and input focus outlines in the Alpine theme
  // --ag-balham-active-color: ; // * (Balham theme only) accent colour used for checked checkboxes, range selections, row selections, and input focus outlines in the Balham theme
  // --ag-material-primary-color: ; // * (Material theme only) the primary colour as defined in the Material Design colour system. Currently this is used on buttons, range selections and selected tab underlines in the Material theme
  // --ag-material-accent-color: ; // * (Material theme only) the accent colour as defined in the Material Design colour system. Currently this is used on checked checkboxes in the Material theme

  // --ag-foreground-color: #{$font-color}; // * Colour of text and icons in primary UI elements like menus - `Salmon` or `#fff`
  // --ag-background-color: #{$bg-color}; // * Background colour of the grid
  // --ag-secondary-foreground-color: ; // * Colour of text and icons in UI elements that need to be slightly less emphasised to avoid distracting attention from data - `Salmon` or `#fff`
  // --ag-data-color: ; // * Colour of text in grid cells - `Salmon` or `#fff`
  // --ag-tooltip-background-color: ; // * Background colour for all tooltips
  // --ag-disabled-foreground-color: ; // * Color of elements that can't be interacted with because they are in a disabled state
  // --ag-subheader-background-color: ; // * Background colour for second level headings within UI components
  // --ag-subheader-toolbar-background-color: ; // * Background colour for toolbars directly under subheadings (as used in the chart settings menu)
  // --ag-control-panel-background-color: ; // * Background for areas of the interface that contain UI controls, like tool panels and the chart settings menu
  // --ag-side-button-selected-background-color: ; // * Background for the active tab on the side of the control panel
  // --ag-selected-row-background-color: ; // * Background color of selected rows in the grid and in dropdown menus
  // --ag-odd-row-background-color: ; // * Background colour applied to every other row
  // --ag-modal-overlay-background-color: ; // * Background color of the overlay shown over the grid e.g. a data loading indicator
  // --ag-row-hover-color: ; // * Background color when hovering over rows in the grid and in dropdown menus. Set to transparent to disable the hover effect. Note: if you want a rollover on one but not the other, use CSS selectors instead of this property
  // --ag-column-hover-color: ; // * Background color when hovering over columns in the grid
  // --ag-range-selection-border-color: ; // * Color to draw around selected cell ranges
  // --ag-range-selection-border-style: ; // * `dotted`, `solid` or `none` - Border style for range selections, e.g. solid or dashed. Do not set to none, if you need to hide the border set the color to transparent
  // --ag-range-selection-background-color: ; // * Background colour of selected cell ranges.
  // --ag-range-selection-background-color-2: ; // * Background-color when 2 selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^2) where X is the opacity of --ag-range-selection-background-color
  // --ag-range-selection-background-color-3: ; // * Background-color when 3 selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^3) where X is the opacity of --ag-range-selection-background-color
  // --ag-range-selection-background-color-4: ; // * Background-color when 4 or more selected ranges overlap. Hint: for a realistic appearance of multiple semi-transparent colours overlaying, set the opacity to 1-((1-X)^4) where X is the opacity of --ag-range-selection-background-color
  // --ag-range-selection-highlight-color: ; // * Background colour to briefly apply to a cell range when it is copied from or pasted into
  // --ag-selected-tab-underline-color: ; // * Colour of the border drawn under selected tabs - `Salmon` or `#fff`
  // --ag-selected-tab-underline-width: ; // * `0`, `4px` or `50%` - Thickness of the border drawn under selected tabs
  // --ag-selected-tab-underline-transition-speed: ; // * `3s` or `250ms` - Duration of animation used to show and hide the border drawn under selected tabs
  // --ag-range-selection-chart-category-background-color: ; // * Background colour for cells that provide categories to the current range chart
  // --ag-range-selection-chart-background-color: ; // * Background colour for cells that provide data to the current range chart
  // --ag-header-cell-hover-background-color: ; // * Rollover colour for header cells. If you set this variable and have enabled column reordering by dragging, you may want to set --ag-header-cell-moving-background-color to ensure that the rollover colour remains in place during dragging.
  // --ag-header-cell-moving-background-color: ; // * Colour applied to header cells when the column is being dragged to a new position
  // --ag-value-change-value-highlight-background-color: ; // * Colour to apply when a cell value changes and enableCellChangeFlash is enabled
  // --ag-value-change-delta-up-color: ; // * Colour to temporarily apply to cell data when its value increases in an agAnimateShowChangeCellRenderer cell
  // --ag-value-change-delta-down-color: ; // * Colour to temporarily apply to cell data when its value decreases in an agAnimateShowChangeCellRenderer cell
  // --ag-chip-background-color: ; // * Colour for the 'chip' that represents a column that has been dragged onto a drop zone

  // --ag-borders-input: ; // * `solid 1px` or `none` - Draw borders around inputs. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
  // --ag-input-border-color: ; // * Colour for borders around inputs, if enabled with --ag-borders-input
  // --ag-borders-input-invalid: ; // * `solid 1px` or `none` - Draw borders around inputs when their content has failed validation. Set this to a border style and thickness, e.g. solid 2px to enable borders. Set to none to disable borders but ensure that you have added styles to differentiate invalid from valid inputs.
  // --ag-input-border-color-invalid: ; // * The color for the border around invalid inputs, if enabled with --ag-borders-input-invalid
  // --ag-borders-side-button: ; // * `solid 1px` or `none` - Draw borders around the vertical tabs on the side of the control panel. Set this to a border style and thickness, e.g. solid 1px to enable borders, or none to disable borders.
  // --ag-border-radius: ; // * `0`, `4px` or `50%` - Border radius applied to many elements such as dialogs and form widgets
  // --ag-invalid-color: ; // * The color applied to form elements in an invalid state
  // --ag-input-disabled-border-color: ; // * The border around disabled text inputs
  // --ag-input-disabled-background-color: ; // * The background colour of disabled text inputs
  // --ag-checkbox-background-color: ; // * the background of an checkbox
  // --ag-checkbox-border-radius: ; // * `0`, `4px` or `50%` - edge rounding a checkboxes
  // --ag-checkbox-checked-color: ; // * Color of the checkbox-checked icon that is shown in a checked checkbox
  // --ag-checkbox-unchecked-color: ; // * Color of the checkbox-unchecked icon that is shown in an unchecked checkbox
  // --ag-checkbox-indeterminate-color: ; // * Color of the checkbox-indeterminate icon that is shown in an indeterminate checkbox
  // --ag-toggle-button-border-width: ; // * `0`, `4px` or `50%` - size of the toggle button outer border
  // --ag-toggle-button-on-border-color: ; // * Colour of the toggle button outer border in its 'on' state - `Salmon` or `#fff`
  // --ag-toggle-button-off-border-color: ; // * Colour of the toggle button's outer border in its 'off' state - `Salmon` or `#fff`
  // --ag-toggle-button-on-background-color: ; // * Colour of the toggle button background in its 'on' state - `Salmon` or `#fff`
  // --ag-toggle-button-off-background-color: ; // * Colour of the toggle button background in its 'off' state - `Salmon` or `#fff`
  // --ag-toggle-button-switch-background-color: ; // * Colour of the toggle button switch (the bit that slides from left to right) - `Salmon` or `#fff`
  // --ag-toggle-button-switch-border-color: ; // * border colour of the toggle button switch (the bit that slides from left to right)
  // --ag-toggle-button-width: ; // * `0`, `4px` or `50%` - width of the whole toggle button component
  // --ag-toggle-button-height: ; // * `0`, `4px` or `50%` - height of the whole toggle button component
  // --ag-input-focus-box-shadow: ; // * `0 5px 10px black` - box shadow around focussed inputs
  // --ag-input-focus-border-color: ; // * Colour of the border around focussed inputs. Set to var(--ag-input-border-color) if you do not want to change the border colour on focus. - `Salmon` or `#fff`
  // --ag-minichart-selected-chart-color: ; // * Color of border around selected chart style
  // --ag-minichart-selected-page-color: ; // * Color of dot representing selected page of chart styles
  // --ag-grid-size: ; // * `0`, `4px` or `50%` - grid-size is the main control for affecting how tightly data and UI elements are packed together. All padding and spacing in the grid is defined as a multiple of grid-size, so increasing it will make most components larger by increasing their internal white space while leaving the size of text and icons unchanged.
  // --ag-icon-size: ; // * `0`, `4px` or `50%` - The size of square icons and icon-buttons
  // --ag-icon-font-weight: ; // * `0`, `4px` or `50%` - The font weight to apply to icon elements, used for icon fonts that require a specific weight to work
  // --ag-widget-container-horizontal-padding: ; // * `0`, `4px` or `50%` - The horizontal padding of containers that contain stacked widgets, such as menus and tool panels
  // --ag-widget-container-vertical-padding: ; // * `0`, `4px` or `50%` - The vertical padding of containers that contain stacked widgets, such as menus and tool panels
  // --ag-widget-horizontal-spacing: ; // * `0`, `4px` or `50%` - The horizontal spacing between widgets in containers that contain horizontally stacked widgets such as the column groups header component.
  // --ag-widget-vertical-spacing: ; // * `0`, `4px` or `50%` - The vertical spacing between widgets in containers that contain vertically stacked widgets
  // --ag-cell-horizontal-padding: ; // * `0`, `4px` or `50%` - Horizontal padding for grid and header cells (vertical padding is not set explicitly, but inferred from row-height / header-height
  // --ag-cell-widget-spacing: ; // * `0`, `4px` or `50%` - Horizontal spacing between widgets inside cells (e.g. row group expand buttons and row selection checkboxes)
  // --ag-row-height: ; // * `0`, `4px` or `50%` - Height of grid rows
  // --ag-header-height: ; // * `0`, `4px` or `50%` - Height of header rows
  // --ag-list-item-height: ; // * `0`, `4px` or `50%` - Height of items in lists (example of lists are dropdown select inputs and column menu set filters)

  // --ag-column-select-indent-size: ; // * `0`, `4px` or `50%` - How much to indent child columns in the column tool panel relative to their parent
  // --ag-set-filter-indent-size: ; // * `0`, `4px` or `50%` - How much to indent child filter items in the Set Filter List relative to their parent
  // --ag-advanced-filter-builder-indent-size: ; // * `0`, `4px` or `50%` - How much to indent child filter items in the Advanced Filter Builder relative to their parent
  // --ag-row-group-indent-size: ; // * `0`, `4px` or `50%` - How much to indent child rows in the grid relative to their parent row
  // --ag-filter-tool-panel-group-indent: ; // * `0`, `4px` or `50%` - How much to indent child columns in the filters tool panel relative to their parent
  // --ag-tab-min-width: ; // * `0`, `4px` or `50%` - Minimum width of a tabbed menu (usd in charts)
  // --ag-menu-min-width: ; // * `0`, `4px` or `50%` - Minimum width of a menu that is not tabbed
  // --ag-side-bar-panel-width: ; // * `0`, `4px` or `50%` - Width of the sidebar that contains the columns and filters tool panels
  // --ag-card-radius: ; // * `0`, `4px` or `50%` - cards are elements that float above the UI
  // --ag-card-shadow: ; // * `0 5px 10px black` - the default card shadow applies to simple cards like column drag indicators and text editors
  // --ag-popup-shadow: ; // * `0 5px 10px black` - override the shadow for popups - cards that contain complex UI, like menus and charts
  // --ag-advanced-filter-join-pill-color: ; // * Colour of the join operator pills in the Advanced Filter Builder - `Salmon` or `#fff`
  // --ag-advanced-filter-column-pill-color: ; // * Colour of the column pills in the Advanced Filter Builder - `Salmon` or `#fff`
  // --ag-advanced-filter-option-pill-color: ; // * Colour of the filter option pills in the Advanced Filter Builder - `Salmon` or `#fff`
  // --ag-advanced-filter-value-pill-color: ; // * Colour of the value pills in the Advanced Filter Builder - `Salmon` or `#fff`

  // !!!!!!!!!!!!!!!!!!!!!!!
  // !!!!!!!!!!!!!!!!!!!!!!!
  // ! Use the above CSS variables whenever possible (as opposed to creating ag class specific styles
  // !!!!!!!!!!!!!!!!!!!!!!!
  // !!!!!!!!!!!!!!!!!!!!!!!
  // * Header font weight and add bottom border
  .ag-header-row {
    font-weight: bold;
    border-bottom: $border-thickness solid $border-color;
  }

  // * Remove the last vertical (quasi border-right) from header
  .ag-header-cell:last-child::after,
  .ag-header-group-cell:last-child::after {
    display: none;
  }

  // * Row font size is dynamic by default.  Set it here for better consistency
  .ag-row {
    font-size: inherit;
  }

  // * Apply border to the top of the grid body
  .ag-row:first-child {
    border-top: $border-thickness solid $border-color;
  }

  // * Remove the last cell border-right on all rows
  .ag-ltr:last-child .ag-cell:last-child {
    border-right: none;
  }

  // * Remove weird gap between header row and body
  .ag-body {
    margin-top: -2px;
  }

  .ag-center-cols-viewport {
    // * AGG's setting of this height value causes horizontal scrollbar to overlap content
    height: unset !important;
  }

  .ag-root-wrapper {
    // * Includes pagination footer
    border: none;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
  }

  // * Apply border to entire grid body
  .ag-root-wrapper-body {
    border: solid $border-color $border-thickness;
  }

  // * Pagination UI
  .ag-paging-panel {
    justify-content: flex-start;
    border-top: 0px;
    // * Apply padding/margin to first element
    > span:nth-child(1) {
      margin-left: 24px;
    }
  }
}
