// * CSS for Barlow Regular
// * Generated by Wakamai Fondue - https://wakamaifondue.com
// * by Roel Nieskens/PixelAmbacht - https://pixelambacht.nl

// * Set custom properties for each layout feature
:root {
  --barlow-aalt: 'aalt' off;
  --barlow-c2sc: 'c2sc' off;
  --barlow-dnom: 'dnom' off;
  --barlow-frac: 'frac' off;
  --barlow-numr: 'numr' off;
  --barlow-ordn: 'ordn' off;
  --barlow-smcp: 'smcp' off;
  --barlow-sups: 'sups' off;
}

// * If class is applied, update custom property and apply modern font-variant-* when supported
.barlow-aalt {
  --barlow-aalt: 'aalt' on;
}

.barlow-c2sc {
  --barlow-c2sc: 'c2sc' on;
}

.barlow-dnom {
  --barlow-dnom: 'dnom' on;
}

.barlow-frac {
  --barlow-frac: 'frac' on;
}

@supports (font-variant-numeric: diagonal-fractions) {
  .barlow-frac {
    --barlow-frac: '____';
    font-variant-numeric: diagonal-fractions;
  }
}

.barlow-numr {
  --barlow-numr: 'numr' on;
}

.barlow-ordn {
  --barlow-ordn: 'ordn' on;
}

@supports (font-variant-numeric: ordinal) {
  .barlow-ordn {
    --barlow-ordn: '____';
    font-variant-numeric: ordinal;
  }
}

.barlow-smcp {
  --barlow-smcp: 'smcp' on;
}

@supports (font-variant-caps: small-caps) {
  .barlow-smcp {
    --barlow-smcp: '____';
    font-variant-caps: small-caps;
  }
}

.barlow-sups {
  --barlow-sups: 'sups' on;
}

@supports (font-variant-position: super) {
  .barlow-sups {
    --barlow-sups: '____';
    font-variant-position: super;
  }
}

// * Apply current state of all custom properties whenever a class is being applied
.barlow-aalt,
.barlow-c2sc,
.barlow-dnom,
.barlow-frac,
.barlow-numr,
.barlow-ordn,
.barlow-smcp,
.barlow-sups {
  font-feature-settings: var(--barlow-aalt), var(--barlow-c2sc), var(--barlow-dnom),
    var(--barlow-frac), var(--barlow-numr), var(--barlow-ordn), var(--barlow-smcp),
    var(--barlow-sups);
}

// * Variable instances (transferred to _typography.scss for DS consistency)
// .barlow-thin {
//   font-variation-settings: 'wght' 30, 'wdth' 500;
// }

// .barlow-extralight {
//   font-variation-settings: 'wght' 39, 'wdth' 500;
// }

// .barlow-light {
//   font-variation-settings: 'wght' 53, 'wdth' 500;
// }

// .barlow-regular {
//   font-variation-settings: 'wght' 71, 'wdth' 500;
// }

// .barlow-medium {
//   font-variation-settings: 'wght' 96, 'wdth' 500;
// }

// .barlow-semibold {
//   font-variation-settings: 'wght' 116, 'wdth' 500;
// }

// .barlow-bold {
//   font-variation-settings: 'wght' 141, 'wdth' 500;
// }

// .barlow-extrabold {
//   font-variation-settings: 'wght' 166, 'wdth' 500;
// }

// .barlow-black {
//   font-variation-settings: 'wght' 188, 'wdth' 500;
// }

// .barlow-thin-italic {
//   font-variation-settings: 'wght' 30, 'wdth' 500;
// }

// .barlow-extralight-italic {
//   font-variation-settings: 'wght' 39, 'wdth' 500;
// }

// .barlow-light-italic {
//   font-variation-settings: 'wght' 53, 'wdth' 500;
// }

// .barlow-italic {
//   font-variation-settings: 'wght' 71, 'wdth' 500;
// }

// .barlow-medium-italic {
//   font-variation-settings: 'wght' 96, 'wdth' 500;
// }

// .barlow-semibold-italic {
//   font-variation-settings: 'wght' 116, 'wdth' 500;
// }

// .barlow-bold-italic {
//   font-variation-settings: 'wght' 141, 'wdth' 500;
// }

// .barlow-extrabold-italic {
//   font-variation-settings: 'wght' 166, 'wdth' 500;
// }

// .barlow-black-italic {
//   font-variation-settings: 'wght' 188, 'wdth' 500;
// }
