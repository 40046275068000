// @use 'abstracts/mixins' as mixins;

// TODO: move to reset?
.a--unstyled {
  color: inherit; // //  !important;
  text-decoration: none; // // !important;
  color: inherit; // // !important;
  text-decoration: none; // // !important;
  font-size: inherit; // // !important;
  font-family: inherit; // // !important;
  font-weight: inherit; // // !important;
  font-variation-settings: inherit;
  line-height: inherit; // // !important;
}

// ? scroll bar width fix
// html::-webkit-scrollbar { width: 0 !important }
// html {
//   overflow: -moz-scrollbars-none;
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }
