// * Layout
.max-w-app {
  max-width: 1920px;
}
.min-w-app {
  min-width: 320px;
}

// * Grid
.grid-cover {
  grid-area: 1 / 1 / -1 / -1;
}
// ? .auto-grid - https://youtu.be/p3_xN2Zp1TY?t=107
.auto-grid {
  --min-column-size: 22rem;
  display: grid;
  gap: var(--gap--grid-gap);
  grid-template-columns: repeat(auto-fit, minmax(min(var(--min-column-size), 100%), 1fr));
}
// ? .grid-reel - https://youtu.be/p3_xN2Zp1TY?t=215
.grid-reel {
  --gap: 1rem;
  display: grid;
  gap: var(--gap);
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - (var(--gap) / 2));
  // grid-auto-columns: 30%; // * or show a bit of the next one so user knows there's more
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding: var(--gap);
}
.grid-reel > * {
  scroll-snap-align: start;
}

// * Effects
.shadow-button {
  // ? Matches Figma DS Button Shadow
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.05);
}

.shadow-card {
  // ? Matches Figma DS Card Shadow
  box-shadow: 0px -2px 8px -1px rgba(0, 0, 0, 0.03), 0px 4px 12px -2px rgba(0, 0, 0, 0.06);
}

// * Typography
html {
  font-size: 62.5%;
}
body {
  font-family: var(--font-main);
  font-variation-settings: var(--font-variation--regular);
  color: var(--color-text);
}
.font-main {
  font-family: var(--font-main);
}
.font-mono {
  font-family: var(--font-mono);
}
.font-inherit {
  font-variation-settings: inherit;
  font-size: inherit;
}
// * HEADINGS (H1-H6)
[class^='heading-'] {
  color: var(--color-heading);
}
.heading-xs {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-3xl);
}
.heading-sm {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-4xl);
}
.heading-md {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-5xl);
}
.heading-lg {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-6xl);
}
.heading-xl {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-7xl);
}
.heading-2xl {
  font-variation-settings: var(--font-variation--semibold);
  font-size: var(--text-9xl);
}

// * SUBHEADINGS (H1-H6)
[class^='subtitle-'],
[class^='subhead-'] {
  color: var(--color-heading);
}
.subtitle-xs,
.subhead-xs {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-3xl);
}
.subtitle-sm,
.subhead-sm {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-4xl);
}
.subtitle-md,
.subhead-md {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-5xl);
}
.subtitle-lg,
.subhead-lg {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-6xl);
}
.subtitle-xl,
.subhead-xl {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-7xl);
}
.subtitle-2xl,
.subhead-2xl {
  font-variation-settings: var(--font-variation--light);
  font-size: var(--text-9xl);
}

:root {
  --font-variation--thin: 'wght' 53, 'wdth' 500;
  --font-variation--extralight: 'wght' 53, 'wdth' 500;
  --font-variation--light: 'wght' 53, 'wdth' 500;
  --font-variation--regular: 'wght' 82, 'wdth' 500;
  --font-variation--medium: 'wght' 96, 'wdth' 500;
  --font-variation--semibold: 'wght' 116, 'wdth' 500;
  --font-variation--bold: 'wght' 141, 'wdth' 500;
  --font-variation--extrabold: 'wght' 166, 'wdth' 500;
  --font-variation--black: 'wght' 166, 'wdth' 500;

  --color-text: var(--graphite);
  --color-heading: var(--black);
  --color-subhead: var(--black);

  --leading-none: 1;
  --leading-tight: 1.25;
  --leading-snug: 1.375;
  --leading-normal: 1.5;
  --leading-relaxed: 1.625;
  --leading-loose: 2;
  --leading-1: 1rem;
  --leading-2: 1.25rem;
  --leading-3: 1.5rem;
  --leading-4: 1.75rem;
  --leading-5: 2rem;
  --leading-6: 2.25rem;
  --leading-7: 2.5rem;
  --leading-8: 2.75rem;
  --leading-9: 3rem;
  --leading-10: 3.25rem;

  --kerning-tighter: -0.05em;
  --kerning-tight: -0.025em;
  --kerning-normal: 0em;
  --kerning-wide: 0.025em;
  --kerning-wider: 0.05em;
  --kerning-widest: 0.1em;

  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-md: 1rem;
  --text-lg: 1.125rem;
  --text-xl: 1.4rem;
  --text-2xl: 1.6rem;
  --text-3xl: 1.875rem;
  --text-4xl: 2.25rem;
  --text-5xl: 3rem;
  --text-6xl: 3.75rem;
  --text-7xl: 4.5rem;
  --text-8xl: 5.25rem;
  --text-9xl: 6rem;
  --text-10xl: 7rem;
  --text-11xl: 8rem;
  --text-12xl: 9rem;
  --text-13xl: 10rem;
  --text-14xl: 11rem;

  --shadow--container: 0px -2px 8px -1px rgba(0, 0, 0, 0.03), 0px 4px 12px -2px rgba(0, 0, 0, 0.06);
  --shadow--border: 0px 0px 0px 1px #eff1f5, 3px 3px 6px -1px rgba(0, 0, 0, 0.04);
  --space--0: 0px;
  --space--px: 1px;
  --space--0-5: 0.6rem;
  --space--1: 0.8rem;
  --space--1-5: 1.2rem;
  --space--2: 1.6rem;
  --space--2-5: 18px;
  --space--3: 2.4rem;
  --space--4: 3.2rem;
  --space--5: 4rem;
  --space--6: 4.8rem;
  --space--7: 5.6rem;
  --gap--content-edge--xs: var(--space--0-5);
  --gap--content-edge--sm: var(--space--1-5);
  --gap--content-edge--base: var(--space--2);
  --gap--content-edge--md: var(--space--2-5);
  --gap--content-edge--lg: var(--space--3);
  --gap--content-edge--xl: var(--space--6);
  --gap--grid-gap: var(--gap--content-edge--base);
  --gap--component-edge: var(--gap--content-edge--sm);
  --thickness--focus-outline: var(--space--1);
  --thickness--focus-outline-2x: var(--space--2);
  --thickness--focus-offset: 0px;
  --radius--sm: var(--space--0-5);
  --radius--base: var(--space--1-5);
  --radius--container: var(--space--1-5);

  --color-success: #22c799;
  --color-warning: #ffcf52;
  --color-error: #f04533;
  --color-error-100: #fee6e9;
  --color-primary-light: #436bef;
  --color-primary-dark: #2d53d1;
  --color-primary: #335eee;
  --color-active-light: #e7edfd;
  --color-active: #dae5fd;
  --yellow: #ffc542;
  --pink: #fe7e9f;
  --purple: #a461d8;
  --celadon: #3bcf88;
  --green: #82c43c;
  --maroon: #912018;
  --red: #fc5a5a;
  --orange: #ff974a;
  --cyan: #01c0f6;
  --blurple: #553afe;
  --blue-900: #001d4d;
  --blue-800: #18328b;
  --blue-700: #284cc5;
  --blue-600: #335eee;
  --blue-500: #0062ff;
  --blue-400: #4b90ff;
  --blue-300: #a9bffb;
  --blue-200: #dae8ff;
  --blue-100: #eef5fe;
  --blue-50: #c8ddff;
  --blue: #0062ff;
  --yellow-100: #fdf2c4;
  --yellow-300: #f8d84d;
  --yellow-600: #e99c00;
  --yellow-700: #c37b00;
  --yellow-800: #8f4d00;
  --red-100: #fee6e9;
  --red-300: #faabac;
  --red-600: #dc3430;
  --red-700: #b52626;
  --red-800: #7e1218;
  --green-100: #def8e5;
  --green-300: #88e6a3;
  --green-600: #019553;
  --green-700: #037a30;
  --green-800: #065500;
  --true-black: #000;
  --graphite: #262b34;
  --black: #12161d;
  --gray-darkest: #40444c;
  --gray-darker-900: #212224;
  --gray-darker-800: #37393c;
  --gray-darker-700: #4e5054;
  --gray-darker-600: #595b60;
  --gray-darker-500: #6f7278;
  --gray-darker-400: #999ba0;
  --gray-darker-300: #aeb0b4;
  --gray-darker-200: #c3c5c8;
  --gray-darker-100: #d9dadc;
  --gray-darker-50: #e0e1e2;
  --gray-darker: #6f7278;
  --gray-dark: #acafb5;
  --gray-900: #12161d;
  --gray-800: #5c6066;
  --gray-700: #7a7d83;
  --gray-600: #acafb5;
  --gray-500: #ced1d7;
  --gray-400: #dde0e5;
  --gray-300: #e6e8ed;
  --gray-200: #eff1f5;
  --gray-100: #f9fafc;
  --gray-50: #f3f4f5;
  --gray: #ced1d7;
  --gray-light: #dde0e5;
  --gray-lighter: #e6e8ed;
  --gray-lightest: #eff1f5;
  --off-white: #f9fafc;
  --white: #fff;
  --color-border: var(--gray-darker-50);

  --transition--ease: ease-out;
  --transition--time: 300ms;
  --transition--fast: 125ms;
  --transition--base: 500ms;
  --transition--slow: 2000ms;
}
