@tailwind base;
@tailwind components;
@tailwind utilities;

/* NOTE: Tailwind organizes the styles it generates into three different “layers” — a concept popularized by ITCSS. */

/* ? - The base layer is for things like reset rules or default styles applied to plain HTML elements. */
/* ? - The components layer is for class-based styles that you want to be able to override with utilities. */
/* ? - The utilities layer is for small, single-purpose classes that should always take precedence over any other styles. */

/* ? Being explicit about this makes it easier to understand how your styles will interact with each other,  */
/* ? and using the '@layer' directive lets you control the final declaration order while still organizing your actual code in whatever way you like. */

@layer utilities {
  .transition-basic {
    /* * transition-all duration-500 ease-in-out */
    transition: all 0.5s ease;
    /* ? ease is like ease-in-out, except it starts slightly faster than it ends */
  }
  .fpo {
    background-color: pink;
    border: solid 1px red;
    color: #aaa;
    font-size: 1rem;
    text-align: center;
    min-width: 50px;
    min-height: 50px;
  }

  .debug-red {
    border: solid 4px rgb(252 90 90);
  }
  .debug-green {
    border: solid 4px rgb(130 196 60);
  }
  .debug-blue {
    border: solid 4px rgb(0 98 255);
  }
  .debug-cyan {
    border: solid 4px rgb(1 192 246);
  }
  .debug-yellow {
    border: solid 4px rgb(255 207 82);
  }
  .debug-black {
    border: solid 4px rgb(18 22 29);
  }
}

@layer base {
  /* ? shadcn classes... */
  :root {
    --base: 309, 100%, 44%;
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;
  }

  /* .dark {
    --base: 87 0 87;
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 240 3.7% 15.9%;
  } */
}

@layer base {
  /* ? shadcn classes... */
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

@layer utilities {
  /* ? shadcn classes... */
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute inline-flex h-9 w-9 items-center justify-center rounded-full border-4 border-background bg-muted text-center -indent-px font-mono font-medium text-base;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }
}

@media (max-width: 640px) {
  /* ? shadcn classes... */
  .container {
    @apply px-4;
  }
}
